import React, { useState } from 'react';
import {
  makeStyles,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  createTheme,
  ThemeProvider,
} from '@material-ui/core';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; // Import the js-cookie library
import ProfilePage from './Profile';
import Dodaj from './Dodaj';
import PrivateRoute from './PrivateRoute';
import QRCodeGenerator from './QR';
import UserProfile from './UserProfile'
import Favourites from './Favourites'
import SharedFavourites from './SharedFavourites';
import AlbumGenerator from './AlbumGenerator';
const theme = createTheme({
  palette: {
    primary: {
      main: '#EB578A',
    },
  },
});


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginPage = ({ setIsAuthenticated }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch('https://ksiega.kalmarry.pl/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        // Set authentication state to true and store user ID
        setIsAuthenticated(true);
        Cookies.set('isAuthenticated', 'true', { expires: 7 });
        Cookies.set('userId', data.user.id, { expires: 7 }); // Store user ID in a cookie
        Cookies.set('first_name', data.user.first_name, { expires: 7 }); // Store first name in a cookie
        navigate(`/profile?id=${data.user.id}&first_name=${data.user.first_name}`);
      } else {
        setError(data.message);
      }
    } catch (error) {
      console.error('Error occurred:', error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={3}>
          <img src="https://kalmarry.pl/wp-content/uploads/elementor/thumbs/NOWE-LOGO-1-qlgdq3f6m9s7aasc1id5y73jg04g7wei0pnkivyusc.png" className={classes.avatar} />
          <Typography component="h1" variant="h5">
            Logowanie
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adres email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Zaloguj się
            </Button>
            {error && <Typography variant="body2" color="error">{error}</Typography>}
          </form>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};



const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('isAuthenticated'));

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/profile" /> : <LoginPage setIsAuthenticated={setIsAuthenticated} />}
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <ProfilePage isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
            </PrivateRoute>
          }
        />
                <Route
          path="/favourites"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <Favourites isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
            </PrivateRoute>
          }
        />
        <Route path="/shared-favourites" element={<SharedFavourites />} />
                <Route
          path="/account"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <UserProfile isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
            </PrivateRoute>
          }
        />
        <Route path="/dodaj" element={<Dodaj />} />

                <Route
          path="/qr"
          element={
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <QRCodeGenerator isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>
            </PrivateRoute>
          }
        />
          <Route
    path="/album-generator"
    element={
      <AlbumGenerator
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
      />
    }
  />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;