import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  Modal,
  Box,
  Grid,
  IconButton,
  Container,
} from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info'; // Dla opisu
import DownloadIcon from '@mui/icons-material/Download'; // Dla pobierania
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const SharedFavourites = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [wpisy, setWpisy] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Aktualny indeks wpisu
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Aktualny indeks obrazu
  const [modalOpen, setModalOpen] = useState(false); // Kontrola widoczności modala
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const [currentWpis, setCurrentWpis] = useState(null);
  const [modalImageIndex, setModalImageIndex] = useState(0); // Nawigacja obrazów w modalu
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://ksiega.kalmarry.pl/returnSharedFavourites.php?token=' + token
        );
        if (!response.ok) throw new Error('Nie udało się pobrać danych');
        const data = await response.json();

        // Przetwarzanie danych z poprawnymi nazwami pól
        const groupedData = [];
        data.forEach((entry) => {
          const existingEntry = groupedData.find(
            (item) => item.wpis_id === entry.wpis_id
          );
          if (existingEntry) {
            existingEntry.images.push(entry.image_path);
          } else {
            groupedData.push({
              wpis_id: entry.wpis_id,
              text: entry.text,
              additional_text: entry.additional_text,
              images: [entry.image_path],
            });
          }
        });

        setWpisy(groupedData);
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    };
    fetchData();
  }, [token]);

  // Funkcja pomocnicza do pobierania URL obrazu
  const getImageUrl = (image) => {
    if (!image || image === 'undefined') return '';
    return image.startsWith('http')
      ? image
      : `https://ksiega.kalmarry.pl/${image}`;
  };

  // Obsługa następnego wpisu (swipe up)
  const handleNext = () => {
    if (currentIndex < wpisy.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentImageIndex(0); // Reset do pierwszego obrazu nowego wpisu
    }
  };

  // Obsługa poprzedniego wpisu (swipe down)
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentImageIndex(0); // Reset do pierwszego obrazu poprzedniego wpisu
    }
  };

  // Obsługa następnego obrazu (swipe left)
  const handleNextImage = () => {
    const images = wpisy[currentIndex]?.images || [];
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  // Obsługa poprzedniego obrazu (swipe right)
  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  // Nawigacja obrazów w modalu na PC
  const handleModalNextImage = () => {
    if (currentWpis && modalImageIndex < currentWpis.images.length - 1) {
      setModalImageIndex(modalImageIndex + 1);
    }
  };

  const handleModalPrevImage = () => {
    if (modalImageIndex > 0) {
      setModalImageIndex(modalImageIndex - 1);
    }
  };

  const handleDownloadAllImages = async () => {
    setLoading(true);
    try {
      const zip = new JSZip();

      // Iteracja przez wszystkie wpisy
      for (const wpis of wpisy) {
        // Iteracja przez obrazy w każdym wpisie
        for (const image of wpis.images) {
          const imageUrl = getImageUrl(image);

          // Pobranie danych obrazu jako blob
          const response = await fetch(imageUrl, {
            mode: 'cors',
          });
          const blob = await response.blob();

          // Generowanie nazwy pliku
          const filename = imageUrl.split('/').pop();

          // Dodanie pliku do ZIP
          zip.file(filename, blob);
        }
      }

      // Generowanie pliku ZIP
      const content = await zip.generateAsync({ type: 'blob' });

      // Uruchomienie pobierania
      saveAs(content, 'images.zip');
    } catch (error) {
      console.error('Error downloading all images:', error);
    } finally {
      setLoading(false);
    }
  };

  // Obsługa gestów przesuwania i przewijania dla desktopu
  const handlers = useSwipeable({
    onSwipedUp: () => handleNext(),
    onSwipedDown: () => handlePrev(),
    onSwipedLeft: () => handleNextImage(),
    onSwipedRight: () => handlePrevImage(),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true, // Włączenie przesuwania myszką dla PC
  });

  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      handlePrev();
    } else if (event.deltaY > 0) {
      handleNext();
    }
  };

  const handleOpenModal = (wpis, imageIndex = 0) => {
    setCurrentWpis({ ...wpis, imageIndex }); // Ustawienie aktualnego wpisu i indeksu obrazu
    setModalImageIndex(imageIndex); // Nawigacja obrazów w modalu
    setModalOpen(true); // Otwarcie modala

    // Dodanie nowego stanu historii, aby zapobiec nawigacji wstecz
    window.history.pushState(null, '', window.location.href);
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Zamknięcie modala
  };

  const handleDownloadImage = async () => {
    let imageUrl;
    if (isMobile) {
      if (!wpisy[currentIndex]?.images[currentImageIndex]) return;

      imageUrl = getImageUrl(wpisy[currentIndex].images[currentImageIndex]);
    } else {
      if (!currentWpis?.images[modalImageIndex]) return;

      imageUrl = getImageUrl(currentWpis.images[modalImageIndex]);
    }

    try {
      // Pobranie danych obrazu jako blob
      const response = await fetch(imageUrl, {
        mode: 'cors', // Upewnij się, że CORS jest włączony
      });
      const blob = await response.blob();

      // Utworzenie URL blobu
      const url = window.URL.createObjectURL(blob);
      const randomFileName = `image_${Date.now()}_${Math.floor(
        Math.random() * 10000
      )}.jpg`;
      // Utworzenie tymczasowego elementu linku
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${randomFileName}`);
      link.style.display = 'none';
      document.body.appendChild(link);

      // Uruchomienie pobierania
      link.click();

      // Czyszczenie
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  // Zapobieganie nawigacji wstecz podczas otwartego modala
  useEffect(() => {
    const handlePopState = (event) => {
      if (modalOpen) {
        setModalOpen(false);
        window.history.pushState(null, '', window.location.href);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [modalOpen]);

  useEffect(() => {
    document.body.style.overscrollBehaviorY = 'none';
    return () => {
      document.body.style.overscrollBehaviorY = 'auto';
    };
  }, []);

  // Preload następnych 3 obrazów na urządzeniach mobilnych
  useEffect(() => {
    if (isMobile && wpisy[currentIndex]) {
      const images = wpisy[currentIndex].images;
      for (let i = 1; i <= 3; i++) {
        if (images[currentImageIndex + i]) {
          const img = new Image();
          img.src = getImageUrl(images[currentImageIndex + i]);
        }
      }
    }
  }, [currentImageIndex, currentIndex, isMobile, wpisy]);

  return (
    <div
      {...handlers}
      onWheel={handleWheel}
      style={{
        flexGrow: 1,
        height: isMobile ? '100vh' : 'auto',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#fff',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        overscrollBehaviorY: 'none', // Wyłączenie pull-to-refresh na urządzeniach mobilnych
      }}
    >
      <AppBar position="fixed" sx={{ backgroundColor: '#EB578A' }}>
        <Toolbar>
          {/* Tytuł aplikacji */}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Udostępnione Ulubione
          </Typography>
        </Toolbar>
      </AppBar>

      <div
        style={{
          height: isMobile ? 'calc(100vh - 64px)' : 'auto',
          flex: isMobile ? 'none' : 1,
          overflow: isMobile ? 'hidden' : 'auto',
          marginTop: isMobile ? 0 : '64px',
        }}
      >
        {isMobile ? (
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              transition: 'transform 0.5s ease-in-out',
              transform: `translateY(-${currentIndex * 100}vh)`,
            }}
          >
            {wpisy.map((wpis, index) => (
              <Card
                key={index}
                sx={{
                  height: '100vh',
                  width: '100%',
                  backgroundColor: '#fff',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#fff',
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  {wpis.images && wpis.images.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          height: '100%',
                          transition: 'transform 0.5s ease-in-out',
                          transform: `translateX(-${currentImageIndex * 100}%)`,
                        }}
                      >
                        {wpis.images.map((image, imgIndex) => (
                          <img
                            key={imgIndex}
                            style={{
                              width: '100%',
                              flexShrink: 0,
                              objectFit: 'contain',
                            }}
                            src={getImageUrl(image)}
                            alt="Wpis image"
                          />
                        ))}
                      </div>
                      {/* Kropki */}
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '140px',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          zIndex: 2,
                        }}
                      >
                        {wpis.images.length > 1 &&
                          wpis.images.map((_, imgIndex) => (
                            <span
                              key={imgIndex}
                              style={{
                                height: '10px',
                                width: '10px',
                                margin: '0 5px',
                                backgroundColor:
                                  imgIndex === currentImageIndex
                                    ? '#EB578A'
                                    : '#ccc',
                                borderRadius: '50%',
                                display: 'inline-block',
                              }}
                            />
                          ))}
                      </div>
                      {/* Pasek nawigacji */}
                      <div
                        style={{
                          position: 'absolute',
                          bottom: '80px',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          zIndex: 2,
                        }}
                      >
                        <IconButton
                          aria-label="Pokaż opis"
                          sx={{
                            margin: '0 20px',
                            color: '#fff',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            },
                          }}
                          onClick={() => handleOpenModal(wpis)}
                        >
                          <InfoIcon />
                        </IconButton>
                        <IconButton
                          aria-label="Pobierz obraz"
                          sx={{
                            margin: '0 20px',
                            color: '#fff',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            },
                          }}
                          onClick={handleDownloadImage}
                        >
                          <DownloadIcon />
                        </IconButton>
                      </div>
                    </>
                  ) : (
                    <Typography style={{ color: '#000' }}>
                      Brak zdjęcia
                    </Typography>
                  )}
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{ padding: 2, width: '100%', margin: 0 }}
          >
            {wpisy.map((wpis, index) => (
              <Grid item xs={4} key={index} sx={{ padding: 1 }}>
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    paddingTop: '100%',
                    overflow: 'hidden',
                    borderRadius: '10px',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {wpis.images && wpis.images.length > 0 ? (
                      <>
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            cursor: 'pointer',
                          }}
                          src={getImageUrl(wpis.images[0])}
                          alt="Wpis image"
                          onClick={() => handleOpenModal(wpis)}
                          loading="lazy" // Lazy loading na PC
                        />
                      </>
                    ) : (
                      <Typography style={{ color: '#000' }}>
                        Brak zdjęcia
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        )}
      </div>

      {/* Komponent Modal */}
      <Modal open={modalOpen} onClose={handleCloseModal} closeAfterTransition>
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#fff',
            boxShadow: 24,
            padding: 0,
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflow: 'hidden',
            outline: 'none',
            margin: 'auto',
            top: '50%',
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            borderRadius: '10px',
          }}
        >
          {currentWpis && (
            <>
              <IconButton
                aria-label="close"
                sx={{
                  position: 'absolute',
                  top: (theme) => theme.spacing(1),
                  right: (theme) => theme.spacing(1),
                  color: '#fff',
                  backgroundColor: 'rgba(0,0,0,0.6)',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.8)',
                  },
                }}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
              {/* Wyświetlanie obrazu(ów) - tylko na desktopie */}
              {!isMobile &&
                currentWpis.images &&
                currentWpis.images.length > 0 && (
                  <>
                    <div
                      style={{
                        flex: 1,
                        position: 'relative',
                        backgroundColor: '#000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {/* Przyciski nawigacji */}
                      {currentWpis.images.length > 1 && (
                        <>
                          <IconButton
                            aria-label="poprzedni obraz"
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              left: (theme) => theme.spacing(1),
                              transform: 'translateY(-50%)',
                              color: '#fff',
                            }}
                            onClick={handleModalPrevImage}
                            disabled={modalImageIndex === 0}
                          >
                            <ArrowBackIosIcon />
                          </IconButton>
                          <IconButton
                            aria-label="następny obraz"
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: (theme) => theme.spacing(1),
                              transform: 'translateY(-50%)',
                              color: '#fff',
                            }}
                            onClick={handleModalNextImage}
                            disabled={
                              modalImageIndex === currentWpis.images.length - 1
                            }
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </>
                      )}
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                        src={getImageUrl(
                          currentWpis.images[modalImageIndex]
                        )}
                        alt="Fullscreen Wpis image"
                        loading="lazy" // Lazy loading w modalu
                      />
                      {/* Przycisk pobierania */}
                      <IconButton
                        aria-label="Pobierz obraz"
                        sx={{
                          position: 'absolute',
                          bottom: (theme) => theme.spacing(1),
                          right: (theme) => theme.spacing(1),
                          color: '#fff',
                          backgroundColor: 'rgba(0,0,0,0.6)',
                          '&:hover': {
                            backgroundColor: 'rgba(0,0,0,0.8)',
                          },
                        }}
                        onClick={handleDownloadImage}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </div>
                    {/* Wyświetlanie opisu */}
                    <Card
                      style={{
                        flex: 1,
                        overflowY: 'auto',
                        margin: 16,
                        borderRadius: 12,
                      }}
                      elevation={4}
                    >
                      <CardContent>
                        {currentWpis.additional_text !== '' && (
                          <Typography variant="subtitle1" color="textSecondary">
                            <b>{currentWpis.additional_text}</b>
                          </Typography>
                        )}

                        <Container>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                          >
                            <b>Opis</b>
                          </Typography>
                          <Typography variant="h6">
                            {currentWpis.text || 'Brak opisu'}
                          </Typography>
                        </Container>
                      </CardContent>
                    </Card>
                  </>
                )}
              {/* Widok mobilny */}
              {isMobile && (
                <Card
                  style={{
                    flex: 1,
                    overflowY: 'auto',
                    margin: 16,
                    borderRadius: 12,
                  }}
                  elevation={4}
                >
                  <CardContent>
                    {currentWpis.additional_text !== '' && (
                      <Typography variant="subtitle1" color="textSecondary">
                        <b>{currentWpis.additional_text}</b>
                      </Typography>
                    )}

                    <Container>
                      <Typography variant="subtitle1" color="textSecondary">
                        <b>Opis</b>
                      </Typography>
                      <Typography variant="h6">
                        {currentWpis.text || 'Brak opisu'}
                      </Typography>
                    </Container>
                  </CardContent>
                </Card>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SharedFavourites;
