import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Paper,
  Button,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import ImageGallery from './ImageGallery';
import BookEditor from './BookEditor';
import PDFGenerator from './PDFGenerator';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const AlbumGenerator = () => {
  const [entries, setEntries] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [bookPages, setBookPages] = useState([]);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id') || 1; // Get ID from URL

  useEffect(() => {
    // Fetch data from the endpoint
    const fetchData = async () => {
      try {
        const response = await fetch(`https://ksiega.kalmarry.pl/returnWpis.php?id=${id}`);
        const data = await response.json();

        // Process and set entries
        const groupedEntries = data.reduce((acc, item) => {
          const existing = acc.find((entry) => entry.id === item.id);
          if (existing) {
            existing.images.push(item.image);
          } else {
            acc.push({
              id: item.id,
              text: item.text,
              images: [item.image],
            });
          }
          return acc;
        }, []);

        setEntries(groupedEntries);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  // Handler for selecting items
  const handleSelectItem = (item) => {
    setSelectedItems((prevItems) => [...prevItems, item]);
  };

  // Handler for updating book pages
  const handleUpdatePages = (pages) => {
    setBookPages(pages);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <AppBar position="static" sx={{ backgroundColor: '#E91E63' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => navigate('/')}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Generator Księgi Gości
            </Typography>
          </Toolbar>
        </AppBar>
        <Container sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6">Dostępne wpisy</Typography>
                <ImageGallery entries={entries} onSelectItem={handleSelectItem} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6">Edytor Księgi</Typography>
                <BookEditor
                  selectedItems={selectedItems}
                  onUpdatePages={handleUpdatePages}
                />
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4 }}>
            <PDFGenerator pages={bookPages} />
          </Box>
        </Container>
      </div>
    </DndProvider>
  );
};

export default AlbumGenerator;
