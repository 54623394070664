import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  createTheme,
  ThemeProvider,
  LinearProgress,
  CircularProgress,
  Box,
  Alert,
  Grid,
  Backdrop,
} from '@mui/material';
import { useLocation } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E91E63', // Pink color
    },
    secondary: {
      main: '#F06292', // Lighter pink color
    },
  },
});

const Dodaj = () => {
  const [text, setText] = useState('');
  const [images, setImages] = useState([]);
  const [error, setError] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [limit, setLimit] = useState(null);
  const [additionalText, setAdditionalText] = useState('');
  const [uploadedImageCount, setUploadedImageCount] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [uploadingImages, setUploadingImages] = useState(false);
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://ksiega.kalmarry.pl/loadData.php?id=' + id,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({}),
          }
        );

        const data = await response.json();
        if (isMounted) {
          setLimit(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Wystąpił błąd podczas pobierania danych.');
      } finally {
        if (isMounted) {
          setLoadingData(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    const userId = id;
    const uploadedImages =
      JSON.parse(localStorage.getItem(`uploadedImages_${userId}`)) || [];
    setUploadedImageCount(uploadedImages.length);
  }, [id]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleImageChange = (e) => {
    const newImages = [...images];
    for (let i = 0; i < e.target.files.length; i++) {
      newImages.push(e.target.files[i]);
    }
    setImages(newImages);
  };

  const handleAdditionalTextChange = (e) => {
    setAdditionalText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadingImages(true);
    setError('');
    setUploadSuccess(false);

    const userId = id;
    const imageLimit = parseInt(limit.user.image_limit);

    const uploadedImages =
      JSON.parse(localStorage.getItem(`uploadedImages_${userId}`)) || [];

    if (images.length === 0) {
      setError('Musisz dodać przynajmniej jedno zdjęcie!');
      setUploadingImages(false);
      return;
    }

    if (uploadedImages.length + images.length > imageLimit) {
      setError(
        `Przekroczono ilość zdjęć, które możesz dodać :( Maksymalna liczba to ${imageLimit}`
      );
      setUploadingImages(false);
      return;
    }

    const formData = new FormData();
    formData.append('text', text);
    formData.append('additional_text', additionalText);
    formData.append('user_id', userId);

    images.slice(0, imageLimit).forEach((image) => {
      formData.append('images[]', image);
    });

    try {
      const response = await fetch('https://ksiega.kalmarry.pl/submit2.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const newUploadedImages = [
          ...uploadedImages,
          ...images.slice(0, imageLimit),
        ];
        localStorage.setItem(
          `uploadedImages_${userId}`,
          JSON.stringify(newUploadedImages)
        );

        setText('');
        setImages([]);
        setAdditionalText('');
        setUploadSuccess(true);
        setUploadedImageCount(newUploadedImages.length);
      } else {
        throw new Error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error occurred:', error);
      setError('Wystąpił błąd. Spróbuj ponownie później.');
    } finally {
      setUploadingImages(false);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const disableForm = uploadingImages || loadingData;

  // Clean up object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      images.forEach((image) => URL.revokeObjectURL(image.preview));
    };
  }, [images]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Paper
          sx={{
            marginTop: 8,
            padding: 4,
          }}
          elevation={3}
        >
          <Typography component="h1" variant="h5" align="center">
            Dodaj zdjęcia i tekst
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
            gutterBottom
          >
            Podziel się swoimi wspomnieniami! Dodaj zdjęcia z uroczystości, aby
            uzupełnić naszą księgę gości.
          </Typography>

          {loadingData && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 4,
              }}
            >
              <CircularProgress color="primary" size={60} />
            </Box>
          )}

          <form onSubmit={handleSubmit}>
            {!uploadSuccess && !loadingData && (
              <>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="additional_text"
                  label="Dodatkowy tekst"
                  name="additional_text"
                  multiline
                  minRows={2}
                  value={additionalText}
                  onChange={handleAdditionalTextChange}
                  disabled={disableForm}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="text"
                  label="Wprowadź tekst"
                  name="text"
                  multiline
                  minRows={4}
                  value={text}
                  onChange={handleTextChange}
                  disabled={disableForm}
                />
              </>
            )}

            {limit && limit.user && limit.user.image_limit && !loadingData && (
              <Typography variant="h6" sx={{ mt: 2 }}>
                Pozostała liczba zdjęć do dodania:{' '}
                {limit.user.image_limit - uploadedImageCount - images.length}
              </Typography>
            )}

            {!uploadSuccess && !loadingData && (
              <>
                <input
                  accept="image/*"
                  id="upload-images"
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  disabled={disableForm}
                />
                <label htmlFor="upload-images">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                    sx={{ mt: 2 }}
                    disabled={disableForm}
                  >
                    Wybierz zdjęcia
                  </Button>
                </label>

                {isMobile && (
                  <>
                    <input
                      accept="image/*"
                      id="take-selfie"
                      type="file"
                      capture="user"
                      onChange={handleImageChange}
                      style={{ display: 'none' }}
                      disabled={disableForm}
                    />
                    <label htmlFor="take-selfie">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        fullWidth
                        sx={{ mt: 2 }}
                        disabled={disableForm}
                      >
                        Zrób selfie!
                      </Button>
                    </label>
                  </>
                )}
              </>
            )}

            {images.length > 0 && !loadingData && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {images.map((image, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Box sx={{ position: 'relative' }}>
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Obraz ${index}`}
                        style={{
                          width: '100%',
                          borderRadius: 8,
                          objectFit: 'cover',
                          height: 150,
                        }}
                      />
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveImage(index)}
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          minWidth: 'unset',
                          padding: 0.5,
                          borderRadius: '50%',
                        }}
                        disabled={disableForm}
                      >
                        ✕
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}

            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

            {uploadSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Wszystkie zdjęcia zostały pomyślnie przesłane!
              </Alert>
            )}

            {!uploadSuccess && !loadingData && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
                disabled={disableForm}
              >
                Wyślij
              </Button>
            )}
          </form>
        </Paper>

        {/* Loading overlay during image upload */}
        <Backdrop
          open={uploadingImages}
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            flexDirection: 'column',
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Trwa przesyłanie zdjęć...
          </Typography>
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
};

export default Dodaj;
