import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Card,
  CardContent,
  Modal,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Container,
  CircularProgress,
} from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import Cookies from 'js-cookie';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info'; // For description
import DownloadIcon from '@mui/icons-material/Download'; // For download
import MenuIcon from '@mui/icons-material/Menu'; // For menu icon
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import AlbumGenerator from './AlbumGenerator';

const ProfilePage = ({ isAuthenticated, setIsAuthenticated }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [wpisy, setWpisy] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current post being viewed
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Track the current image in the wpis
  const [modalOpen, setModalOpen] = useState(false); // Control modal visibility
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id') || 1;
  const loggedInUserId = Cookies.get('userId');
  const [currentWpis, setCurrentWpis] = useState(null);
  const [modalImageIndex, setModalImageIndex] = useState(0); // For PC modal image navigation
  const [loading, setLoading] = useState(false);
  // State for menu
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [favoriteEntries, setFavoriteEntries] = useState([]);
const [viewFavorites, setViewFavorites] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (id !== loggedInUserId) {
      Cookies.remove('isAuthenticated');
      setIsAuthenticated(false);
      navigate('/login', { replace: true });
      return;
    }
  }, [id, loggedInUserId, setIsAuthenticated, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://ksiega.kalmarry.pl/returnWpis.php?id=' + id
        );
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
  
        // Group images by `wpis.id`
        const groupedData = [];
        data.forEach((entry) => {
          const existingEntry = groupedData.find((item) => item.id === entry.id);
          if (existingEntry) {
            existingEntry.images.push(entry.image);
          } else {
            groupedData.push({
              ...entry,
              images: [entry.image],
              liked: entry.liked === 1, // Convert liked status to boolean
            });
          }
        });
  
        setWpisy(groupedData);
  
        // Extract favorite entries
        const favorites = groupedData.filter((wpis) => wpis.liked);
        setFavoriteEntries(favorites);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [id]);

  const handleToggleLike = async (wpisId) => {
    try {
      // Find the wpis in the state
      const updatedWpisy = wpisy.map((wpis) => {
        if (wpis.id === wpisId) {
          return { ...wpis, liked: !wpis.liked };
        }
        return wpis;
      });
      setWpisy(updatedWpisy);
  
      // Update favorite entries
      const favorites = updatedWpisy.filter((wpis) => wpis.liked);
      setFavoriteEntries(favorites);
  
      // Send request to saveFavourites.php
      const action = updatedWpisy.find((wpis) => wpis.id === wpisId).liked
        ? 'like'
        : 'unlike';
  
      const response = await fetch('https://ksiega.kalmarry.pl/saveFavourites.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: id, wpis_id: wpisId, action }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update favorite status');
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };

  // Helper function to get image URL
  const getImageUrl = (image) => {
    if (!image) return '';
    return image.startsWith('http')
      ? image
      : `https://ksiega.kalmarry.pl/${image}`;
  };

  // Handle next post (swipe up)
  const handleNext = () => {
    if (currentIndex < wpisy.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentImageIndex(0); // Reset to first image of the new post
    }
  };

  // Handle previous post (swipe down)
  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentImageIndex(0); // Reset to first image of the previous post
    }
  };

  // Handle next image (swipe left)
  const handleNextImage = () => {
    const images = wpisy[currentIndex]?.images || [];
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  // Handle previous image (swipe right)
  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  // Handle modal image navigation on PC
  const handleModalNextImage = () => {
    if (currentWpis && modalImageIndex < currentWpis.images.length - 1) {
      setModalImageIndex(modalImageIndex + 1);
    }
  };

  const handleModalPrevImage = () => {
    if (modalImageIndex > 0) {
      setModalImageIndex(modalImageIndex - 1);
    }
  };

  const handleDownloadAllImages = async () => {
    setLoading(true);
    try {
      const zip = new JSZip();
  
      // Loop through all entries (wpisy)
      for (const wpis of wpisy) {
        // Loop through images in each wpis
        for (const image of wpis.images) {
          const imageUrl = getImageUrl(image);
  
          // Fetch the image data as a blob
          const response = await fetch(imageUrl, {
            mode: 'cors',
          });
          const blob = await response.blob();
  
          // Generate a filename (you can customize this)
          const filename = imageUrl.split('/').pop();
  
          // Add the file to the ZIP
          zip.file(filename, blob);
        }
      }
  
      // Generate the ZIP file
      const content = await zip.generateAsync({ type: 'blob' });
  
      // Trigger the download
      saveAs(content, 'images.zip');
    } catch (error) {
      console.error('Error downloading all images:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle swiping and mouse wheel scrolling for desktop
  const handlers = useSwipeable({
    onSwipedUp: () => handleNext(),
    onSwipedDown: () => handlePrev(),
    onSwipedLeft: () => handleNextImage(),
    onSwipedRight: () => handlePrevImage(),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true, // Enable mouse-based swiping for PC
  });

  const handleWheel = (event) => {
    if (event.deltaY < 0) {
      handlePrev();
    } else if (event.deltaY > 0) {
      handleNext();
    }
  };

  const handleShareClick = () => {
    const BASE_URL = window.location.origin;
    const qrPageUrl = `${BASE_URL}/qr?id=${id}`;
    window.location.href = qrPageUrl;
  };
  const handleProfileRedirect = (isAuthenticated) =>
  {
    const BASE_URL = window.location.origin;
    const qrPageUrl = `${BASE_URL}/account?id=${id}`;
    window.location.href = qrPageUrl;
  }

  const handleFavouritesRedirect = () =>
    {
      const BASE_URL = window.location.origin;
      const qrPageUrl = `${BASE_URL}/favourites?id=${id}`;
      window.location.href = qrPageUrl;
    }
  
    const handleKsiegaGosciRedirect = () =>
    {
      const BASE_URL = window.location.origin;
      const qrPageUrl = `${BASE_URL}/profile?id=${id}`;
      window.location.href = qrPageUrl;
    }
  const handleOpenModal = (wpis, imageIndex = 0) => {
    setCurrentWpis({ ...wpis, imageIndex }); // Set the current wpis and image index
    setModalImageIndex(imageIndex); // For PC modal image navigation
    setModalOpen(true); // Open the modal

    // Push a new history state to prevent back navigation
    window.history.pushState(null, '', window.location.href);
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
  };

  const handleDownloadImage = async () => {
    let imageUrl;
    if (isMobile) {
      if (!wpisy[currentIndex]?.images[currentImageIndex]) return;

      imageUrl = getImageUrl(wpisy[currentIndex].images[currentImageIndex]);
    } else {
      if (!currentWpis?.images[modalImageIndex]) return;

      imageUrl = getImageUrl(currentWpis.images[modalImageIndex]);
    }

    try {
      // Fetch the image data as a blob
      const response = await fetch(imageUrl, {
        mode: 'cors', // Ensure CORS is enabled
      });
      const blob = await response.blob();

      // Create a blob URL
      const url = window.URL.createObjectURL(blob);
      const randomFileName = `image_${Date.now()}_${Math.floor(Math.random() * 10000)}.jpg`;
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${randomFileName}`);
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  // Handle logout
  const handleLogout = () => {
    Cookies.remove('isAuthenticated');
    Cookies.remove('userId');
    setIsAuthenticated(false);
    navigate('/login', { replace: true });
  };

  // Menu handlers
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Prevent back navigation when modal is open
  useEffect(() => {
    const handlePopState = (event) => {
      if (modalOpen) {
        setModalOpen(false);
        window.history.pushState(null, '', window.location.href);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [modalOpen]);

  useEffect(() => {
    document.body.style.overscrollBehaviorY = 'none';
    return () => {
      document.body.style.overscrollBehaviorY = 'auto';
    };
  }, []);

  // Preload next 3 images on mobile
  useEffect(() => {
    if (isMobile && wpisy[currentIndex]) {
      const images = wpisy[currentIndex].images;
      for (let i = 1; i <= 3; i++) {
        if (images[currentImageIndex + i]) {
          const img = new Image();
          img.src = getImageUrl(images[currentImageIndex + i]);
        }
      }
    }
  }, [currentImageIndex, currentIndex, isMobile, wpisy]);

  return (
    <div
      {...handlers}
      onWheel={handleWheel}
      style={{
        flexGrow: 1,
        height: isMobile ? '100vh' : 'auto',
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#fff',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        overscrollBehaviorY: 'none', // Disable pull-to-refresh on mobile
      }}
    >
      <AppBar position="fixed" sx={{ backgroundColor: '#EB578A' }}>
        <Toolbar>
          {/* Menu Icon for additional options */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          {/* App Title */}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Księga Gości
          </Typography>
          {/* Share Button */}
          <IconButton color="inherit" onClick={handleShareClick}>
            <ShareIcon />
          </IconButton>
          {/* Logout Button */}
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Menu Items */}
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
        {/* Add more menu items as needed */}
        <MenuItem onClick={handleProfileRedirect}>Profil</MenuItem>
        <MenuItem onClick={handleProfileRedirect}>Księga Gości</MenuItem>
        <MenuItem onClick={handleFavouritesRedirect}>Ulubione</MenuItem>
        <MenuItem
  onClick={() => {
    handleMenuClose();
    handleDownloadAllImages();
  }}
  disabled={loading}
>
  {loading ? 'Pobieranie...' : 'Pobierz Wszystkie Zdjęcia'}
</MenuItem>
        
      </Menu>

      {isAuthenticated ? (
        <div
          style={{
            height: isMobile ? 'calc(100vh - 64px)' : 'auto',
            flex: isMobile ? 'none' : 1,
            overflow: isMobile ? 'hidden' : 'auto',
            marginTop: isMobile ? 0 : '64px',
          }}
        >
          {isMobile ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.5s ease-in-out',
                transform: `translateY(-${currentIndex * 100}vh)`,
              }}
            >
              {wpisy.map((wpis, index) => (
                <Card
  key={index}
  sx={{
    height: '100vh',
    width: '100%',
    backgroundColor: '#fff',
    position: 'relative',
  }}
>
  {/* Double-tap handler */}
  <div
    onDoubleClick={() => handleToggleLike(wpis.id)}
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
                    {wpis.images && wpis.images.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            height: '100%',
                            transition: 'transform 0.5s ease-in-out',
                            transform: `translateX(-${currentImageIndex * 100}%)`,
                            
                          }}
                        >
                          {wpis.images.map((image, imgIndex) => (
                            <img
                              key={imgIndex}
                              style={{
                                width: '100%',
                                flexShrink: 0,
                                objectFit: 'contain',
                                
                              }}
                              src={getImageUrl(image)}
                              alt="Wpis image"
                            />
                          ))}
                        </div>
                        {/* Dots */}
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '140px',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            zIndex: 2,
                          }}
                        >
                          {wpis.images.length > 1 &&
                            wpis.images.map((_, imgIndex) => (
                              <span
                                key={imgIndex}
                                style={{
                                  height: '10px',
                                  width: '10px',
                                  margin: '0 5px',
                                  backgroundColor:
                                    imgIndex === currentImageIndex
                                      ? '#EB578A'
                                      : '#ccc',
                                  borderRadius: '50%',
                                  display: 'inline-block',
                                }}
                              />
                            ))}
                        </div>
                        {/* Navigation Bar */}
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '80px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            zIndex: 2,
                          }}
                        >
                          <IconButton
                            aria-label="Show Description"
                            sx={{
                              margin: '0 20px',
                              color: '#fff',
                              backgroundColor: 'rgba(0, 0, 0, 0.6)',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                              },
                            }}
                            onClick={() => handleOpenModal(wpis)}
                          >
                            <InfoIcon />
                          </IconButton>
                          <IconButton
                            aria-label="Download Image"
                            sx={{
                              margin: '0 20px',
                              color: '#fff',
                              backgroundColor: 'rgba(0, 0, 0, 0.6)',
                              '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                              },
                            }}
                            onClick={handleDownloadImage}
                          >
                            <DownloadIcon />
                          </IconButton>
                          <IconButton
      aria-label="Like"
      sx={{
        position: 'absolute',
        top: (theme) => theme.spacing(1),
        left: (theme) => theme.spacing(1),
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.6)',
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.8)',
        },
      }}
      onClick={() => handleToggleLike(wpis.id)}
    >
      {wpis.liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
    </IconButton>
                        </div>
                      </>
                    ) : (
                      <Typography style={{ color: '#000' }}>
                        Brak zdjęcia
                      </Typography>
                    )}
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <Grid container spacing={2} sx={{ padding: 2, width: '100%', margin: 0, }}>
              {wpisy.map((wpis, index) => (
                <Grid item xs={4} key={index} sx={{ padding: 1 }}>
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      paddingTop: '100%',
                      overflow: 'hidden',
                      borderRadius: '10px'
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      {wpis.images && wpis.images.length > 0 ? (
                        <>
                        <img
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            cursor: 'pointer',
                          }}
                          src={getImageUrl(wpis.images[0])}
                          alt="Wpis image"
                          onClick={() => handleOpenModal(wpis)}
                          loading="lazy" // Lazy loading on PC
                        />

                        <IconButton
            aria-label="Like"
            sx={{
              position: 'absolute',
              top: (theme) => theme.spacing(1),
              left: (theme) => theme.spacing(1),
              color: '#fff',
              backgroundColor: 'rgba(0,0,0,0.6)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.8)',
              },
            }}
            onClick={() => handleToggleLike(wpis.id)}
          >
            {wpis.liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
                        </>
                        
                        
                      ) : (
                        <Typography style={{ color: '#000' }}>
                          Brak zdjęcia
                        </Typography>
                      )}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      ) : (
        <Navigate to="/" />
      )}

      {/* Modal Component */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#fff',
            boxShadow: 24,
            padding: 0,
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflow: 'hidden',
            outline: 'none',
            margin: 'auto',
            top: '50%',
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            borderRadius: "10px"
          }}
        >
          {currentWpis && (
            <>
              <IconButton
                aria-label="close"
                sx={{
                  position: 'absolute',
                  top: (theme) => theme.spacing(1),
                  right: (theme) => theme.spacing(1),
                  color: '#fff',
                  backgroundColor: 'rgba(0,0,0,0.6)',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.8)',
                  },
                }}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
              {/* Display the image(s) - only on desktop */}
              {!isMobile && currentWpis.images && currentWpis.images.length > 0 && (
                <>
                  <div
                    style={{
                      flex: 1,
                      position: 'relative',
                      backgroundColor: '#000',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {/* Navigation Buttons */}
                    {currentWpis.images.length > 1 && (
                      <>
                        <IconButton
                          aria-label="previous image"
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: (theme) => theme.spacing(1),
                            transform: 'translateY(-50%)',
                            color: '#fff',
                          }}
                          onClick={handleModalPrevImage}
                          disabled={modalImageIndex === 0}
                        >
                          <ArrowBackIosIcon />
                        </IconButton>
                        <IconButton
                          aria-label="next image"
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            right: (theme) => theme.spacing(1),
                            transform: 'translateY(-50%)',
                            color: '#fff',
                          }}
                          onClick={handleModalNextImage}
                          disabled={
                            modalImageIndex === currentWpis.images.length - 1
                          }
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </>
                    )}
                    <img
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                      src={getImageUrl(currentWpis.images[modalImageIndex])}
                      alt="Fullscreen Wpis image"
                      loading="lazy" // Lazy loading in modal
                    />
                    {/* Download Button */}
                    <IconButton
                      aria-label="Download Image"
                      sx={{
                        position: 'absolute',
                        bottom: (theme) => theme.spacing(1),
                        right: (theme) => theme.spacing(1),
                        color: '#fff',
                        backgroundColor: 'rgba(0,0,0,0.6)',
                        '&:hover': {
                          backgroundColor: 'rgba(0,0,0,0.8)',
                        },
                      }}
                      onClick={handleDownloadImage}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </div>
                  {/* Display the description */}
                  <Card
  style={{
    flex: 1,
    overflowY: 'auto',
    margin: 16,
    borderRadius: 12,
  }}
  elevation={4}
>
  <CardContent>
    {currentWpis.additional_text != "" && (    <Typography variant="subtitle1" color="textSecondary">
      <b>{currentWpis.additional_text}</b>
    </Typography>) }

    <Container>
    <Typography variant="subtitle1" color="textSecondary">
      <b>Opis</b>
    </Typography>
      <Typography variant="h6">
        {currentWpis.text || 'Brak opisu'}
      </Typography>
    </Container>
  </CardContent>
</Card>
                </>
              )}
              {/* Mobile view fallback */}
              {isMobile && (
                  <Card
                  style={{
                    flex: 1,
                    overflowY: 'auto',
                    margin: 16,
                    borderRadius: 12,
                  }}
                  elevation={4}
                >
                  <CardContent>
                    {currentWpis.additional_text != "" && (    <Typography variant="subtitle1" color="textSecondary">
                      <b>{currentWpis.additional_text}</b>
                    </Typography>) }
                
                    <Container>
                    <Typography variant="subtitle1" color="textSecondary">
                      <b>Opis</b>
                    </Typography>
                      <Typography variant="h6">
                        {currentWpis.text || 'Brak opisu'}
                      </Typography>
                    </Container>
                  </CardContent>
                </Card>
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ProfilePage;
