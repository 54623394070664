import React from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Checkbox } from '@mui/material';
import { useDrag } from 'react-dnd';

const ImageGallery = ({ entries }) => {
  return (
    <List sx={{ maxHeight: 600, overflow: 'auto' }}>
      {entries.map((entry) =>
        entry.images.map((image, index) => {
          const key = `${entry.id}_${image}`;
          return <DraggableImage key={key} entry={entry} image={image} />;
        })
      )}
    </List>
  );
};

const DraggableImage = ({ entry, image }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: { entryId: entry.id, image, text: entry.text },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <ListItem ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <ListItemAvatar>
        <Avatar
          src={`https://ksiega.kalmarry.pl/${image}`}
          alt=""
          variant="square"
        />
      </ListItemAvatar>
    </ListItem>
  );
};

export default ImageGallery;
