import React, { useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { useDrop } from 'react-dnd';
import PagePreview from './PagePreview';

const BookEditor = ({ selectedItems, onUpdatePages }) => {
  const [pages, setPages] = React.useState([]);

  // Handle drop of items onto pages
  const [, drop] = useDrop({
    accept: 'image',
    drop: (item, monitor) => {
      // Add item to pages
      setPages((prevPages) => {
        const newPages = [...prevPages];
        const lastPage = newPages[newPages.length - 1] || { items: [] };
        if (lastPage.items.length < 2) {
          lastPage.items.push(item);
          if (newPages.length === 0) {
            newPages.push(lastPage);
          } else {
            newPages[newPages.length - 1] = lastPage;
          }
        } else {
          newPages.push({ items: [item] });
        }
        return newPages;
      });
    },
  });

  useEffect(() => {
    // Whenever pages change, inform parent component
    onUpdatePages(pages);
  }, [pages, onUpdatePages]);

  return (
    <Box ref={drop}>
      {pages.length === 0 && (
        <Typography variant="body1">Przeciągnij zdjęcia tutaj, aby utworzyć strony.</Typography>
      )}
      <Grid container spacing={2}>
        {pages.map((page, index) => (
          <Grid item xs={12} key={index}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="subtitle1">Strona {index + 1}</Typography>
              <PagePreview items={page.items} />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BookEditor;