import React from 'react';
import { Grid, Box } from '@mui/material';

const PagePreview = ({ items }) => {
  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item xs={6} key={index}>
          <Box sx={{ border: '1px solid #ccc', p: 1 }}>
            <img
              src={`https://ksiega.kalmarry.pl/${item.image}`}
              alt=""
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default PagePreview;