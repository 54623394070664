// UserProfile.js

import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Card,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Container,
  CircularProgress,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const UserProfile = ({ isAuthenticated, setIsAuthenticated }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const loggedInUserId = Cookies.get('userId');

  // Ensure the user ID from the URL matches the logged-in user's ID
  useEffect(() => {
    if (id !== loggedInUserId) {
      Cookies.remove('isAuthenticated');
      Cookies.remove('userId');
      setIsAuthenticated(false);
      navigate('/login', { replace: true });
      return;
    }
  }, [id, loggedInUserId, setIsAuthenticated, navigate]);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `https://ksiega.kalmarry.pl/returnUser.php?id=${id}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  // Handle logout
  const handleLogout = () => {
    Cookies.remove('isAuthenticated');
    Cookies.remove('userId');
    setIsAuthenticated(false);
    navigate('/login', { replace: true });
  };

  // Menu handlers
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const handleProfileRedirect = (isAuthenticated) =>
    {
      const BASE_URL = window.location.origin;
      const qrPageUrl = `${BASE_URL}/account?id=${id}`;
      window.location.href = qrPageUrl;
    }
    const handleFavouritesRedirect = () =>
    {
      const BASE_URL = window.location.origin;
      const qrPageUrl = `${BASE_URL}/favourites?id=${id}`;
      window.location.href = qrPageUrl;
    }
  
    const handleKsiegaGosciRedirect = () =>
    {
      const BASE_URL = window.location.origin;
      const qrPageUrl = `${BASE_URL}/profile?id=${id}`;
      window.location.href = qrPageUrl;
    }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return (
      <div
        style={{
          flexGrow: 1,
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!userData) {
    return (
      <div
        style={{
          flexGrow: 1,
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6">Nie udało się załadować danych użytkownika.</Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        flexGrow: 1,
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#fff',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar position="fixed" sx={{ backgroundColor: '#EB578A' }}>
        <Toolbar>
          {/* Menu Icon for additional options */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          {/* App Title */}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Profil Użytkownika
          </Typography>
          {/* Logout Button */}
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Menu Items */}
      <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
      <MenuItem onClick={handleProfileRedirect}>Profil</MenuItem>
        <MenuItem onClick={handleKsiegaGosciRedirect}>Księga Gości</MenuItem>
        <MenuItem onClick={handleFavouritesRedirect}>Ulubione</MenuItem>
      </Menu>

      {/* Main Content */}
      <Container
        maxWidth="md"
        sx={{
          marginTop: '80px',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            width: '100%',
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: 3,
            borderRadius: 2,
          }}
        >
          {/* User Avatar */}
          <Avatar
            alt={`${userData.first_name} ${userData.last_name}`}
            sx={{ width: 120, height: 120, marginBottom: 2 }}
          >
            {userData.first_name[0]}
            {userData.last_name[0]}
          </Avatar>
          {/* User Name */}
          <Typography variant="h5" gutterBottom>
            {`${userData.first_name} ${userData.last_name}`}
          </Typography>
          {/* User Email */}
          <Typography variant="body1" color="textSecondary" gutterBottom>
            {userData.email}
          </Typography>
          {/* User Image Limit */}
          <Typography variant="body2" color="textSecondary" align="center">
            Limit zdjęć: {userData.image_limit} na osobę
          </Typography>
          {/* User Image Limit */}
         <Typography variant="body2" color="textSecondary" align="center">
            Twój abonament kończy się: {userData.data}
          </Typography>
        </Card>
      </Container>
    </div>
  );
};

export default UserProfile;
