import React, { useRef, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PagePreview from './PagePreview';

const PDFGenerator = ({ pages }) => {
  const [loading, setLoading] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const previewRef = useRef();

  const handleGeneratePDF = async () => {
    setLoading(true);
    try {
      const input = previewRef.current;
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('portrait', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      const pdfBlob = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfBlobUrl(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setLoading(false);
    }
  };

  // Clean up blob URL
  React.useEffect(() => {
    return () => {
      if (pdfBlobUrl) {
        URL.revokeObjectURL(pdfBlobUrl);
      }
    };
  }, [pdfBlobUrl]);

  return (
    <div>
      <Box ref={previewRef} sx={{ p: 2, border: '1px solid #ccc', mb: 2 }}>
        {pages.map((page, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="subtitle1">Strona {index + 1}</Typography>
            <PagePreview items={page.items} />
          </Box>
        ))}
      </Box>
      <Button variant="contained" color="primary" onClick={handleGeneratePDF} disabled={loading}>
        {loading ? 'Generowanie PDF...' : 'Generuj PDF'}
      </Button>
      {pdfBlobUrl && (
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="secondary" href={pdfBlobUrl} download="wedding_book.pdf">
            Pobierz PDF
          </Button>
        </Box>
      )}
    </div>
  );
};

export default PDFGenerator;
