import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Paper,
  IconButton,
  Button,
  Menu,
  MenuItem,
  ThemeProvider,
  createTheme,
} from '@material-ui/core';
import { QRCodeSVG } from 'qrcode.react';
import { useLocation, useNavigate } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import Cookies from 'js-cookie';

const theme = createTheme({
  palette: {
    primary: {
      main: '#EB578A',
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
  },
});

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    flexGrow: 1,
    minHeight: '100vh',
    width: '100%',
    backgroundColor: '#fff',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Lato, sans-serif',
  }),
  appBar: {
    backgroundColor: '#EB578A',
  },
  title: {
    flexGrow: 1,
  },
  container: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    maxWidth: 600,
    margin: 'auto',
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  qrCodeContainer: {
    marginTop: theme.spacing(4),
  },
  qrCode: {
    margin: 'auto',
  },
  shareButton: {
    marginTop: theme.spacing(2),
    backgroundColor: '#EB578A',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d14e7b',
    },
  },
  downloadButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#EB578A',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#d14e7b',
    },
  },
  outlinedButton: {
    marginTop: theme.spacing(2),
    borderColor: '#EB578A',
    color: '#EB578A',
    '&:hover': {
      backgroundColor: 'rgba(235, 87, 138, 0.08)',
    },
  },
}));

const QRCodeGenerator = ({ isAuthenticated, setIsAuthenticated }) => {
  const classes = useStyles();
  const [userId, setUserId] = useState('');
  const [qrCodeValue, setQrCodeValue] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id') || 1;

  const loggedInUserId = Cookies.get('userId');

  // State for menu
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    // Check if the logged-in user ID matches the profile ID
    if (id !== loggedInUserId) {
      // Logout the user if IDs do not match
      Cookies.remove('isAuthenticated');
      Cookies.remove('userId');
      Cookies.remove('first_name');
      setIsAuthenticated(false);
      navigate('/login', { replace: true });
      return; // Exit the effect early
    }
  }, [id, loggedInUserId, setIsAuthenticated, navigate]);

  useEffect(() => {
    if (id) {
      setUserId(id);
      // Generate the link immediately after retrieving the user ID
      const link = `https://ksiega.kalmarry.pl/Dodaj?id=${id}`;
      setQrCodeValue(link);
    } else {
      alert('Id użytkownika nie został przekazany w parametrach URL.');
    }
  }, [id]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(qrCodeValue).then(
      () => {
        alert('Link został skopiowany do schowka!');
      },
      (err) => {
        console.error('Error copying text: ', err);
      }
    );
  };

  const handleShareClick = () => {
    const BASE_URL = window.location.origin;
    const qrPageUrl = `${BASE_URL}/qr?id=${id}`;
    window.location.href = qrPageUrl;
  };

  const handleLogout = () => {
    Cookies.remove('isAuthenticated');
    Cookies.remove('userId');
    setIsAuthenticated(false);
    navigate('/login', { replace: true });
  };

  // Menu handlers
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleShareFacebook = () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      qrCodeValue
    )}`;
    window.open(shareUrl, '_blank');
  };

  const handleDownloadQRCode = () => {
    const svgElement = document.getElementById('qrCodeSVG');
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement('canvas');
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext('2d');
      // White background
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'qr-code.png';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  };

  const handleKsiegaRedirect = () =>
  {
    const BASE_URL = window.location.origin;
    const qrPageUrl = `${BASE_URL}/profile?id=${id}`;
    window.location.href = qrPageUrl;
  }
  const handleProfileRedirect = () =>
    {
      const BASE_URL = window.location.origin;
      const qrPageUrl = `${BASE_URL}/account?id=${id}`;
      window.location.href = qrPageUrl;
    }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            {/* Menu Icon for additional options */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            {/* App Title */}
            <Typography variant="h6" className={classes.title}>
              Udostępnij
            </Typography>
            {/* Share Button */}
            <IconButton color="inherit" onClick={handleShareClick}>
              <ShareIcon />
            </IconButton>
            {/* Logout Button */}
            <IconButton color="inherit" onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {/* Menu Items */}
        <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          {/* Add more menu items as needed */}
          <MenuItem onClick={handleKsiegaRedirect}>Księga Gości</MenuItem>
          <MenuItem onClick={handleProfileRedirect}>Profil</MenuItem>
        </Menu>

        <Container className={classes.container}>
          <Paper className={classes.paper} elevation={3}>
            <Typography component="h1" variant="h5" className={classes.title}>
              Udostępnij kod dla znajomych
            </Typography>
            {qrCodeValue && (
              <div className={classes.qrCodeContainer}>
                <Typography variant="h6">Twój kod QR:</Typography>
                <QRCodeSVG
                  id="qrCodeSVG"
                  value={qrCodeValue}
                  className={classes.qrCode}
                  size={256}
                />
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ marginTop: '16px' }}
                >
                  Skorzystaj z aplikacji skanującej kod QR, aby przejść do strony
                  Dodaj.
                </Typography>
                <Button
                  variant="contained"
                  className={classes.downloadButton}
                  onClick={handleDownloadQRCode}
                >
                  Zapisz kod QR
                </Button><br></br>
                <Button
                  variant="contained"
                  className={classes.shareButton}
                  onClick={handleShareFacebook}
                  startIcon={<ShareIcon />}
                >
                  Udostępnij na Facebooku
                </Button>
                <Button
                  variant="outlined"
                  className={classes.outlinedButton}
                  onClick={handleCopyLink}
                >
                  Skopiuj link
                </Button>
              </div>
            )}
          </Paper>
        </Container>
      </div>
    </ThemeProvider>
  );
};

export default QRCodeGenerator;
